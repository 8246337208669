<template>
    <div
        class="admin-discount-button"
        :class="{
            bchollos: $isBchollos,
            grayscale: btnDisabled,
        }"
    >
        <button
            type="button"
            @click="() => openCoupon()"
            class="admin-discount-button__copy"
            :class="{
                'no-code': !discount.code,
                isRecommendedFromOthersStores,
                isRecommended,
                bchollos: $isBchollos,
                countryCouponMx: isMexicoButton,
                countryCouponEa: isSpainButton,
            }"
        >
            <p
                :style="{
                    fontSize:
                        percentSize >= 10
                            ? percentSize < 15
                                ? '21px'
                                : percentSize < 17
                                  ? '19px'
                                  : '16px'
                            : undefined,
                }"
                :class="{
                    'letter-sizing': percentSize < 10 && percentSize > 0,
                }"
            >
                {{ codeText }}
            </p>
        </button>
    </div>
</template>
<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    props: {
        discount: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        store: {
            type: Object as PropType<Models.Store>,
        },
        btnDisabled: {
            type: Boolean,
        },
        openCoupon: {
            type: Function,
            default: (newPageOnly: boolean) => {
                return null
            },
        },
        isRecommendedFromOthersStores: {
            type: Boolean,
            default: false,
        },
        isRecommended: {
            type: Boolean,
            default: false,
        },
        isMexicoButton: {
            type: Boolean,
            default: false,
        },
        isSpainButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            copied: false,
            copying: false,
            textAnimated: '',
        }
    },
    computed: {
        codeText(): string {
            if (this.discount.code && this.discount.code.trim().length) {
                return this.discount.code
            }
            return this.$lang.components.btnDiscount.no_coupon_required
        },
        percentSize(): number {
            return this.discount.code?.length || 0
        },
    },
})
</script>

<style lang="postcss" scoped>
.admin-discount-button {
    @apply relative min-h-12 w-full rounded-[8px];

    &__copy {
        @apply h-full min-h-[inherit] w-full rounded-[8px] border-2 border-dashed border-site-primary px-2;

        @apply relative flex flex-col items-center justify-center bg-site-primary bg-opacity-10;

        p {
            @apply line-clamp-1 flex-none break-all text-center font-semibold text-gray-800;

            &.letter-sizing {
                @apply text-xl lg:text-2xl;
            }
        }

        &.isRecommendedFromOthersStores {
            @apply !border-[#ef764d] bg-[#EFB4A0] bg-opacity-10;
        }

        &.isRecommended {
            @apply !border-[#fbd19e] bg-[#FAEBD7] bg-opacity-10;
        }
        &.countryCouponMx {
            @apply !border-[#014D38] bg-[#016845] bg-opacity-10;
        }
        &.countryCouponEa {
            @apply !border-[#8B0F14] bg-[#AD1318] bg-opacity-10;
        }
    }

    &--modal-width {
        @apply w-[237px];
    }
}
</style>
