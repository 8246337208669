<template>
    <div
        class="admin-discount-button"
        :class="{
            bchollos: $isBchollos,
            grayscale: btnDisabled,
        }"
    >
        <button
            type="button"
            :class="{
                isRecommendedFromOthersStores,
                isRecommended,
                bchollos: $isBchollos,
                countryCouponMx: isMexicoButton,
                countryCouponEa: isSpainButton,
            }"
            class="admin-discount-button__button"
            @click="() => handler()"
        >
            <slot></slot>
        </button>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        btnDisabled: {
            type: Boolean,
        },
        isRecommendedFromOthersStores: {
            type: Boolean,
            default: false,
        },
        isRecommended: {
            type: Boolean,
            default: false,
        },
        handler: {
            type: Function,
            default: (e: any) => null,
        },
        isOffer: {
            type: Boolean,
            default: false,
        },
        isMexicoButton: {
            type: Boolean,
            default: false,
        },
        isSpainButton: {
            type: Boolean,
            default: false,
        },
    },
})
</script>

<style lang="postcss" scoped>
.admin-discount-button {
    @apply relative h-12 w-full rounded-[8px];

    &__button {
        @apply relative h-full w-full rounded-[8px] bg-site-primary-hover-light text-lg font-bold text-site-primary-text-contrast;
    }
}

.isRecommendedFromOthersStores {
    @apply !bg-[#EFB4A0] !text-black;
}

.countryCouponMx {
    @apply !bg-[#016845] !text-white;
}

.countryCouponEa {
    @apply !bg-[#AD1318] !text-white;
}

.isRecommended {
    @apply !bg-[#FAEBD7] !text-black;
}

.bchollos {
    @apply bg-[#02B979];
}
</style>
